<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container">
      <router-link to="/" class="navbar-brand"> <img class="navbar-logo" alt="Świętosław" src="../assets/logo_fixed.png"> </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <img src="../assets/icons/list.svg" alt="Instagram" width="32" height="32">
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <router-link to="/" class="nav-link">Oferta</router-link>
          <router-link to="/historia" class="nav-link">Historia</router-link>
          <router-link to="/mapa" class="nav-link"> Gdzie kupić </router-link>
          <router-link to="/kontakt" class="nav-link"> Kontakt </router-link>
        </div>
        <div style="margin-left: auto" id="social_links">
        <div class="navbar-nav">
          <a class="nav-link" href="https://www.instagram.com/browarswietoslaw" target="_blank"> <img src="../assets/icons/instagram.svg" alt="Instagram" width="32" height="32"></a>
          <a class="nav-link" href="https://www.facebook.com/browarswietoslaw" target="_blank"> <img src="../assets/icons/facebook.svg" alt="Facebook" width="32" height="32"></a>
        </div>
      </div>
      </div>
      
    </div>
  </nav>
  <div style="background-color: rgb(44, 62, 80)" class="color-bar"></div>
</template>

<script>
export default {
  name: "AppHeader"
}
</script>

<style scoped>
@media only screen and (max-width: 575px) {
  div.color-bar {
    height: 0.5rem;
  }
}
.color-bar{
  height: 0.8rem;
  width: 100%
}

.navbar-brand{
  margin-top: -5px;
  margin-bottom: -85px;
  @media(max-width: 991px) {
    margin-bottom: -5px;
  }
}
.navbar-logo{
  width: 150px;
  height: 150px;
  @media(max-width: 991px) {
  width: 100px;
  height: 100px;
  }
}

@media only screen and (min-width: 768px) {
  .px-md-5 {
    padding: 0 3rem !important;
  }
}
</style>