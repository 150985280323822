<template>
    <div>
        <div class ="text-center" style="position: absolute; z-index: 1001; width: 100%; height: 100%; background-color: white; top: 0; left: 0">
            <div class="container my-4">
                <img class="text-center mb-4 agreement-logo" src="@/assets/logo_black_white.png">
                <h1 class="fw-bold display-4"> Czy masz ukończone 18 lat? </h1>
                <div class="text-center">
                    <div class="col-md-8 mx-auto my-4">
                        <button type="button" v-on:click=saveAgreementCookie class="btn agreement-button btn-outline-success btn-lg mx-5">Tak</button>
                        <button type="button" class="btn agreement-button btn-outline-secondary btn-lg mx-5">Nie</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import historyData from "@/assets/raw_data/historyData.json"
    export default {
        name: 'App',
        methods: {
            saveAgreementCookie: function () {
                this.$cookies.set("adultAgreement", true, '24h');
                if (this.$route.query.redirect != undefined)
                    this.$router.push(`${this.$route.query.redirect}`)
                else
                    this.$router.push(`/`)
            }
        },
        data () {
        return {
            historyData: historyData
        }
        }
    }
</script>
<style>
    .agreement-button {
    min-width: 7rem;
    @media(max-width: 991px) {
        margin-top: 1rem;
    }
    }
    .agreement-logo {
        max-height: 300px;
        @media(min-width: 576px) {
            max-height: 400px;
        }
        @media(min-width: 768px) {
            max-height: 450px;
        }
        @media(min-width: 992px) {
            max-height: 500px;
        }
    }
</style>