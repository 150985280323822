<template>
    <div class="row col-md-10 mx-auto pt-5">
        <div class="col-md-3 col-12 text-center">
            <img v-if="custom_image" :src=beer_image :style=custom_image :title=image_title>
            <img v-else :src=beer_image height=400>
        </div>
        <div class="col-md-9 col-12 ">
            <div class="px-md-5 text-center">
                <h1 v-if="beer_title" class="display-6 fw-bold">{{ beer_title }}</h1>
                <div class="mx-auto">
                    <p class="lead text-start" v-html="beer_description"></p>
                    <div v-if="!(beer_badges.length > 0)">
                        <p class="lead lead-smaller text-start" v-html="beer_properties"></p>
                    </div>
                    <div v-else class="row">
                        <div class="col-md-9 col-12">
                            <p class="lead lead-smaller text-start" v-html="beer_properties"></p>
                        </div>
                        <div class="col-md-3 col-12">
                            <img v-for="(badge,index) in beer_badges" :src=badge.image :key="index" :width="125" :alt=badge.title :title=badge.title>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BeerCard",
    props: ['beer_title', 'image_title', 'custom_image', 'beer_image', 'beer_description', 'beer_properties', 'beer_badges']
}
</script>
<style>
.beer-properties {
  color: grey;
}
.lead.lead-smaller {
  font-size: 1rem;
  br {
    font-size: 0.5rem;
  }
}
</style>