export function middle_of_stores(stores) {
    var sum_lon = 0.0;
    var sum_lat = 0.0;
    stores.forEach(store => {
        sum_lat += store.latitude;
        sum_lon += store.longitude;
    })
    return [sum_lat/stores.length, sum_lon/stores.length]
}
export function store_info(store) {
    var store_data = `<p><b>Nazwa:</b><br>${store.name}</p>
    <p><b>Adres:</b><br>${store.address}</p>`
    if(store.url !=null)
    {
        store_data += `<a href="${store.url}">Strona lokalu</a>`
    }
    return store_data
}