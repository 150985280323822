<template>
  <div>
    <div class="text-center">
      <div class="container px-4 pb-4">
        <img src="../../public/contact/brothers.jpg"  class="img-fluid border rounded-3 shadow-lg" style="max-height: 500px;" loading="lazy">
      </div>
    </div>
    <div class="row col-md-6 mx-auto pb-4">
      <div class="col-md-6 col-12 text-center">
                <div class="mx-auto">
                    <p class="lead"> <b>Krzysztof Kasprzyk</b></p>
                    <p class="lead"> +48 732 940 159 </p>
                </div>
        </div>
      <div class="col-md-6 col-12 text-center">
          <div class="mx-auto">
            <p class="lead"> <b>Bartłomiej Kasprzyk</b> </p>
            <p class="lead"> +48 728 857 406 </p>
          </div>
      </div>
    </div>
    <div class="row col-md-10 mx-auto">
        <div class="col-lg-4 col-12 text-center">
          <div class="mx-auto">
              <p class="lead"> <b>Adresy email</b></p>
              <p class="lead"> zamowienia.swietoslaw@gmail.com </p>
              <p class="lead"> warzelnia.piwa@gmail.com </p>
          </div>
          <div class="mx-auto">
              <p class="lead"> <b>Adres korespondencyjny</b></p>
              <p class="lead"> ul. Transportowców 3<br>39-200 Dębica </p>
          </div>
          <div class="mx-auto">
              <p class="lead"> <b>Browar, sklep stacjonarny</b></p>
              <p class="lead"> Głowaczowa 95A<br>39-217 Głowaczowa </p>
              
          </div>
        </div>
        <div class="col-lg-8 col-12">
            <div id="nodesMap" class="px-md-5 text-center img-fluid border rounded-3 shadow-lg" style="height: 400px;">
            </div>
        </div>
    </div>
  </div>
</template>
<script>
  import "leaflet/dist/leaflet.css";
  import L from "leaflet";
  import "@/utilities.js"
  export default {
  name: 'App',
  methods: {
    setupLeafletMap: function () {
      const mapDiv = L.map("nodesMap").setView([50.041142874596844, 21.312794484223524], 12);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(mapDiv)
      L.marker([50.041142874596844, 21.312794484223524], {icon: L.icon({
            iconUrl: "marker_green.png",
            iconSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [0, -30]
          })}).addTo(mapDiv)
          .bindPopup(`<p><b>Warzelnia Piwa sp. z.o.o</b><br>Głowaczowa 95A<br>39-217 Głowaczowa</p>`)
    },
  },
  async mounted() {
    this.setupLeafletMap();
  }
}
</script>