<template>
  <div>
    <div class="text-center" style="width: 100%;">
      <div class="container px-4">
        <div id="nodesMap" style="height: 38rem; background-color: rgb(71, 71, 71);" class="img-fluid border rounded-3 shadow-lg mb-4">
          <h1> Przepraszam, mapa nie działa </h1>
        </div>
      </div>
      <h1 class="display-5 fw-bold"> Mapa punktów sprzedaży naszych piw</h1>
    
    </div>
  </div>
</template>
<script>
  import "leaflet/dist/leaflet.css";
  //import axios from 'axios';
import L from "leaflet";
import "@/utilities.js"
import { store_info } from "@/utilities";
//import storesData from "../assets/raw_data/storesData.json"
import axios from 'axios';
export default {
  name: 'App',
  methods: {
    setupLeafletMap: function (stores) {
      const mapDiv = L.map("nodesMap").setView([52.213436007821116, 18.82083015755922], 6);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(mapDiv)
      stores.forEach((store) => {
        L.marker([store.latitude, store.longitude], {icon: L.icon({
            iconUrl: "marker_red.png",
            iconSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [0, -30]
          })}).addTo(mapDiv)
          .bindPopup(store_info(store))
      })
    },
  },
  async mounted() {
    let storesData = await axios.get("https://swietoslaw.pl/storesData.json").then(response => {
          return response.data
      })
    this.setupLeafletMap(storesData);
  }
}
</script>