<template>
  <div>
    <div class="text-center">
      <div class="container px-4 pb-4">
        <img src="../../public/history/history_first_photo.jpg"  class="img-fluid border rounded-3 shadow-lg" style="max-height: 500px;" loading="lazy">
      </div>
      <h1 class="display-5 fw-bold">Historia browaru</h1>
      <div class="col-lg-10 mx-auto">
        <p class="lead mb-4">Browar Świętosław Dębicki to niewielkie, rodzinne przedsiębiorstwo, które powstało w 2020r. z pasji do piwowarstwa domowego.
<br><br>Nazwa „Świętosław Dębicki” pochodzi od rycerza służącego Kazimierzowi Wielkiemu. W dowód uznania Świętosław Gryfita otrzymał od króla przywilej alokacyjny – uprawniający do założenia miasta Dębicy. Herb Świętosława Gryfity to gryf na czerwonym polu – używany do tej pory jako herb Dębicy. Również my dumnie reprezentujemy gryfa w logo naszej firmy.</p>
      </div>
      <div class="container px-4 pb-4">
        <img src="../../public/history/natalia_kris.jpg"  class="img-fluid border rounded-3 shadow-lg" style="max-height: 500px;" loading="lazy">
      </div>
      <div class="col-lg-10 mx-auto">
        <p class="lead mb-4">Od samego początku działalności o receptury i czystość procesu dbają piwowarzy – Krzysztof Kasprzyk oraz Natalia Kubik.</p>
      </div>
    </div>
  </div>
</template>
<script>
import historyData from '../assets/raw_data/historyData.json'
  export default {
    name: 'App',
    methods: {},
    data () {
      return {
        historyData: historyData
      }
    }
  }
</script>