<template>
    <div>
        <div class="text-center">
            <h1 class="display-5 fw-bold"> Polityka Prywatności </h1>
            <div class="col-lg-12 mx-auto">
                <div class="lead mb-4 text-start fs-5">
                    <p><b>I. Postanowienia ogólne</b></p>
                    <ol type="1">
                        <li>Polityka prywatności określa, jak zbierane, przetwarzane i przechowywane są dane osobowe Użytkowników niezbędne do świadczenia usług drogą elektroniczną za pośrednictwem serwisu internetowego swietoslaw.pl (dalej: Serwis).</li>
                        <li>Serwis zbiera wyłcznie dane osobowe niezbędne do świadczenia i rozwoju usług w nim oferowanych.</li>
                        <li>Dane osobowe zbierane za pośrednictwem Serwisu są przetwarzane zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony os&oacute;b fizycznych w zwi&#261;zku z przetwarzaniem danych osobowych i w sprawie swobodnego przep&#322;ywu takich danych oraz uchylenia dyrektywy 95/46/WE (og&oacute;lne rozporz&#261;dzenie o ochronie danych, dalej RODO) oraz ustaw&#261; o ochronie danych osobowych z dnia 10 maja 2018 r.</li>
                    </ol>
                    <p><b>II. Administrator danych</b></p>
                    <p>Administratorem danych osobowych zbieranych poprzez Serwis jest Warzelnia Piwa Sp. z o. o., adres: 39-200 Dębica, Transportowców 3, KRS: 0000679439, NIP: 8722416406, REGON: 367334596, adres poczty elektronicznej: warzelnia.piwa@gmail.com (dalej: Administrator).</p>
                    <p><b>III. Cel zbierania danych osobowych</b><br></p>
                        <ol type="1">
                            <li>
                                <p>Dane osobowe wykorzystywane są w celu:</p>
                                <ul>
                                    <li>rejestracji konta i weryfikacji to&#380;samo&#347;ci U&#380;ytkownika</li>
                                    <li>umo&#380;liwienia logowania do Serwisu</li>
                                    <li>realizacji umowy dotycz&#261;cej us&#322;ug i e-us&#322;ug</li>
                                    <li>komunikacji z U&#380;ytkownikiem (livechat, formularz kontaktowy itp.)</li>
                                    <li>wysy&#322;ki newslettera (po wyra&#380;eniu zgody U&#380;ytkownika na jego otrzymywanie)</li>
                                    <li>prowadzenia systemu komentarzy</li>
                                    <li>świadczenia usług spo&#322;eczno&#347;ciowych</li>
                                    <li>promocji oferty Administratora</li>
                                    <li>marketingu, remarketingu, afiliacji</li>
                                    <li>personalizacji Serwisu dla Użytkownikó;w</li>
                                    <li>działań analitycznych i statystycznych</li>
                                    <li>windykacji nale&#380;no&#347;ci</li>
                                    <li>ustalenia i dochodzenia roszcze&#324; albo obrony przed nimi.</li>
                                </ul>
                            </li>
                            <br><li><p>Podanie danych jest dobrowolne, ale niezbędne do zawarcia umowy albo skorzystania z innych funkcjonalności Serwisu.</p></li>
                        </ol>
                    <p><b>IV. Rodzaj przetwarzanych danych osobowych</b></p>
                        <p>Administrator mo&#380;e przetwarza&#263; dane osobowe U&#380;ytkownika: imię i nazwisko, data urodzenia, adres zamieszkania, adres e-mail, numer telefonu, NIP.<br></p>
                    <p><b>V. Okres przetwarzania danych osobowych</b>
                    </p><p>Dane osobowe U&#380;ytkownik&oacute;w b&#281;d&#261; przetwarzane przez okres:</p> 
                    <ul>
                        <li>gdy podstawą przetwarzania danych jest wykonanie umowy - do momentu przedawnienia roszczeń po jej wykonaniu</li>
                        <li>gdy podstawą przetwarzania danych jest zgoda - do momentu jej odwołania, a po odwołaniu zgody do przedawnienia roszczeń</li>
                    </ul>
                    <p>W obu przypadkach termin przedawnienia wynosi 6 lat, a dla roszczeń o świadczenia okresowe i roszczeń dotyczących prowadzenia działalności gospodarczej - 3 lata (jeśli przepis szczególny nie stanowi inaczej).</p>
                    <p><b>VI. Udostępnianie danych osobowych</b></p>
                    <ol type="1">
                        <li>Dane osobowe Użytkowników mogą być przekazywane: podmiotom powiązanym z Administratorem, jego podwykonawcom, podmiotom współpracującym z Administratorem np. firmom obsługującym e-płatności, firmom świadczącym usługi kurierskie/pocztowe, kancelariom prawnym.</li>
                        <li>Dane osobowe Użytkowników będą przekazywane poza teren Europejskiego Obszaru Gospodarczego (EOG).</li>
                    </ol>
                    <p><b>VII. Prawa Użytkowników</b></p>
                    <ol type="1">
                        <li>Użytkownik Serwisu ma prawo do: dostępu do treści swoich danych osobowych, ich sprostowania, usunięcia, ograniczenia przetwarzania, przenoszenia, wniesienia sprzeciwu wobec przetwarzania, cofnięcia zgody w każdej chwili (co nie ma wpływu na zgodność z prawem przetwarzania dokonanego w oparciu o zgodę przed jej cofnięciem).</li>
                        <li>Zgłoszenie o wystąpieniu przez Użytkownika z uprawnieniem wynikającym z wymienionych praw należy przesłać na adres warzelnia.piwa@gmail.com.</li>
                        <li>Administrator spełnia lub odmawia spełnienia żądania niezwłocznie – maksymalnie w ciągu miesiąca od jego otrzymania.</li>
                        <li>Użytkownik ma prawo złożyć skargę do Prezesa Urzędu Ochrony Danych Osobowych, jeśli uzna, że przetwarzanie narusza jego prawa i wolności (RODO).</li>
                    </ol>
                    <p><b>VIII. Pliki cookies</b></p>
                    <ol type="1">
                        <li>Serwis zbiera informacje za pomocą plików cookies – sesyjnych, stałych i podmiotów zewnętrznych.</li>
                        <li>Zbieranie plików cookies wspiera poprawne świadczenie usług w Serwisie i służy celom statystycznym.</li>
                        <li>Użytkownik może określić zakres dostępu plików cookies do swojego urządzenia w ustawieniach przeglądarki.</li>
                    </ol>
                    <p><b>IX. Zautomatyzowane podejmowanie decyzji i profilowanie</b></p>
                    <ol type="1">
                        <li>Dane Użytkowników nie mogą być przetwarzane w zautomatyzowany sposób tak, że na skutek tego mogłyby zapaść wobec nich jakiekolwiek decyzje</li>
                        <li>Dane Użytkowników mogą być profilowane celem dostosowania treści i personalizacji oferty po wyrażeniu przez nich zgody.</li>
                    </ol>
                    <p><b>X. Postanowienia ko&#324;cowe</b></p>
                    <ol type="1">
                        <li>Administrator ma prawo do wprowadzenia zmian w Polityce prywatności, przy czym prawa Użytkowników nie zostaną ograniczone.</li>
                        <li>Informacja o wprowadzonych zmianach pojawi się w formie komunikatu dostępnego w Serwisie.</li>
                        <li>W sprawach nieuregulowanych w niniejszej Polityce prywatności obowiązują przepisy RODO i przepisy prawa polskiego.</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'App',
        methods: {},
        data () {
            return {
            }
        }
    }
</script>