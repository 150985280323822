<template>
  <div style="background-color: rgb(44, 62, 80)" class="color-bar"></div>
  <div style="background-color: black;" class="pb-4">
  <div class="container">
  <footer class="pt-4">
    <div class="row">
      <div class="col-12 col-md-4 text-center">
        <h5>Kontakt</h5>
        <div class="nav flex-column">
          <p class="nav-item mb-2 text-body-secondary"> Warzelnia Piwa Sp. z o. o.<br>39-200 Dębica<br>Transportowców 3</p>
          <p class="nav-item mb-2 text-body-secondary"> +48 732 940 159 </p>
          <p class="nav-item mb-2 text-body-secondary"> warzelnia.piwa@gmail.com </p>
        </div>
      </div>

      <div class="col-12 col-md-4 text-center">
        <h5>Przydatne Linki</h5>
        <ul class="nav flex-column">
          <li class="nav-item mb-1"><router-link to="/"  class="nav-link p-0 text-body-secondary">Oferta</router-link></li>
          <li class="nav-item mb-1"><router-link to="/historia" class="nav-link p-0 text-body-secondary">Historia</router-link></li>
          <li class="nav-item mb-1"><router-link to="/mapa" class="nav-link p-0 text-body-secondary"> Gdzie kupić </router-link></li>
          <li class="nav-item mb-1"><router-link to="/kontakt" class="nav-link p-0 text-body-secondary"> Kontakt </router-link></li>
          <li class="nav-item mb-1"><router-link to="/politykaprywatnosci" class="nav-link p-0 text-body-secondary"> Polityka Prywatności </router-link></li>
        </ul>
      </div>

      <div class="col-12 col-md-4 text-center">
        <h5>Social Media</h5>
        <ul class="nav flex-column">
          <li class="nav-item mb-2"><a class="nav-link p-0 text-body-secondary" href="https://www.instagram.com/browarswietoslaw" target="_blank"> Instagram </a></li>
          <li class="nav-item mb-2"><a class="nav-link p-0 text-body-secondary" href="https://www.facebook.com/browarswietoslaw" target="_blank"> Facebook </a></li>
        </ul>
      </div>    
    </div>
  </footer>
  </div>
</div>
<div style="background: black">
  <div class="d-flex flex-column flex-sm-row justify-content-center text-center pt-4 border-top border-white" style="background-color: black">
        <p>© 2023 Warzelnia Piwa sp. z o. o. Wszelkie prawa zastrzeżone.</p>
  </div>
</div>
</template>

<script>
export default {
  name: "AppHeader"
}
</script>

<style scoped>
@media only screen and (max-width: 575px) {
  div.color-bar {
    height: 0.5rem;
  }
}
.color-bar{
  height: 0.8rem;
  width: 100%
}

.navbar-brand{
  margin-top: -5px;
  margin-bottom: -85px;
}
.footer-link {
  display: block;
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: white;
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
.nav-item a {
  color: white !important;
}
footer p, footer h5 {
  color: white !important;
}
.border-white {
  border-color: white !important;
  
}
.border-white p {
    color: white;
  }

.footer-link:hover, .footer-link:focus {
    color: var(--bs-nav-link-hover-color);
}

@media only screen and (min-width: 768px) {
  .px-md-5 {
    padding: 0 3rem !important;
  }
}
</style>