<template>
  <div>
    <h1 class="text-center display-4 fw-bold pb-2">Oferta browaru<br>Świętosław Dębicki</h1>
    
    <p class="text-center row col-md-10 mx-auto lead" style="display: block;">Browar Świętosław Dębicki to miejsce, gdzie z pasją tworzymy piwo z największą dokładnością i starannością, używając tylko naturalnych składników. Piwo dostarczamy często i małymi partiami, aby zawsze zapewniać naszym Klientom najwyższą jakość oraz świeżość. Nasz browar został odznaczony prestiżowym tytułem <span class="fw-bold p-0">„Mistrzowie Jasnych Lagerów”</span> przyznanym przez międzynarodowe grono jury Konkursu Piw Rzemieślniczych „KPR – Kraft Roku”.</p>
    <div class="text-center">
    <img src="mistrzowie_jasnych_lagerów.png" style="max-width:100%; max-height:400px" title='Nagroda specjalna "Mistrzowie Jasnych Lagerów 2023" Konkurs Piw Rzemieślniczych Kraft Roku 2023'>
    </div>
    <BeerCard v-for="(beer,index) in beers_data.available" :beer_title="beer.title" :beer_image="beer.image" :custom_image="beer.custom_image" :image_title="beer.image_title" :beer_description="beer.description" :beer_properties="beer.properties" :beer_badges="beer.badges" :key="index"/>
    <h1 class="text-center display-4 fw-bold pt-5">Dodatkowo</h1>
    <BeerCard v-for="(beer,index) in beers_data.additional" :beer_title="beer.title" :beer_image="beer.image" :custom_image="beer.custom_image" :image_title="beer.image_title" :beer_description="beer.description" :beer_properties="beer.properties" :beer_badges="beer.badges" :key="index"/>
    <h1 class="text-center display-4 fw-bold pt-5">Piwa tymczasowo niedostępne</h1>
    <BeerCard v-for="(beer,index) in beers_data.unavailable" :beer_title="beer.title" :beer_image="beer.image" :custom_image="beer.custom_image" :image_title="beer.image_title" :beer_description="beer.description" :beer_properties="beer.properties" :beer_badges="beer.badges" :key="index"/>
  </div>
  </template>
  <script>
  import BeerCard from "@/components/BeerCard"
  import beersData from '../assets/raw_data/beersData.json'
  export default {
    name: 'App',
    components: { BeerCard },
    methods: {
    },
    data () {
      return {
        beers_data: beersData
    }}
  }
  </script>
<style>
  
</style>