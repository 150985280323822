<template>
  <AppHeader/>
  <div class="container-fluid middle-height">
    <router-view class="main-content container py-6"/>
  </div>
  <AppFooter/>
  <div class="container-fluid">
  <div id="ciasteczkowy-numer" class="text-center mb-0 ciasteczkowa-klasa">
    <div class="container-fluid py-4">
      Korzystając ze strony zgadzasz się na
      <router-link to="/politykaprywatnosci" class="text-body-secondary">Politykę prywatności oraz plików Cookies</router-link>.
      <button type="button" class="btn btn-light btn-sm ms-3" v-on:click=hideCookieBanner>
      Zamknij
      </button>
    </div>
  </div>

  </div>
</template>
<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter
  },
  methods: {
    showCookieBanner: function (){
      let cookieBanner = document.getElementById("ciasteczkowy-numer");
      cookieBanner.style.display = "block";
    },
    hideCookieBanner: function(){
      this.$cookies.set("cookiesAgreement", true, '24h');
      let cookieBanner = document.getElementById("ciasteczkowy-numer");
      cookieBanner.style.display = "none";
    },
    initializeCookieBanner: function(){
      if(this.$cookies.get("cookiesAgreement") != 'true')
      {
        this.showCookieBanner();
      }
    }
  },
  async mounted() {
    this.initializeCookieBanner();
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0;
  height: 100%;
}
html,
body {
  height: 100%;
  margin: 0;
}
.py-6{
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}
.ciasteczkowa-klasa { position: fixed !important; bottom: 0; left: 0; width: 100%; z-index: 1002; background: lightgray !important; display: none;}
.middle-height {
  min-height: calc(57.1%)
}
</style>
