import { createRouter, createWebHistory} from 'vue-router'
import HomePage from "@/views/HomePage";
import BeerMap from "@/views/BeerMap";
import History from "@/views/History";
import Contact from "@/views/Contact";
import Agreement from "@/views/Agreement";
import PrivacyPolicy from "@/views/PrivacyPolicy";
const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage,
        meta: {
            title: "Oferta"
        }
    },
    {
        path: '/mapa',
        name: 'Mapa',
        component: BeerMap,
        meta: {
            title: "Mapa dostępności"
        }
    },
    {
        path: '/historia',
        name: 'Historia',
        component: History,
        meta: {
            title: "Historia"
        }
    },
    {
        path: '/kontakt',
        name: 'Kontakt',
        component: Contact,
        meta: {
            title: "Kontakt"
        }
    },
    {
        path: '/zgoda',
        name: 'Zgoda',
        component: Agreement,
        meta: {
            title: "Zgoda"
        }
    },
    {
        path: '/politykaprywatnosci',
        name: 'Polityka Prywatności',
        component: PrivacyPolicy,
        meta: {
            title: "Polityka Prywatności"
        }
    }
]
const router = createRouter({
    linkActiveClass: 'active',
    history: createWebHistory(process.env.BASE_URL),
    routes: routes
})

const DEFAULT_PAGE_TITLE = "Świętosław Dębicki"
router.afterEach((to) => {
    if (window.$cookies.get("adultAgreement") != 'true' && to.path != '/zgoda' && to.path != '/politykaprywatnosci') {
        router.push({ path: "/zgoda", query: { redirect: to.path } })
    }
    document.title = to.meta.title ? DEFAULT_PAGE_TITLE + " - " + to.meta.title : DEFAULT_PAGE_TITLE;
  });
export default router